import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment-timezone';
import { motion } from 'framer-motion';

const SunIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-sun-filled" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M12 19a1 1 0 0 1 .993 .883l.007 .117v1a1 1 0 0 1 -1.993 .117l-.007 -.117v-1a1 1 0 0 1 1 -1z" stroke-width="0" fill="currentColor" />
  <path d="M18.313 16.91l.094 .083l.7 .7a1 1 0 0 1 -1.32 1.497l-.094 -.083l-.7 -.7a1 1 0 0 1 1.218 -1.567l.102 .07z" stroke-width="0" fill="currentColor" />
  <path d="M7.007 16.993a1 1 0 0 1 .083 1.32l-.083 .094l-.7 .7a1 1 0 0 1 -1.497 -1.32l.083 -.094l.7 -.7a1 1 0 0 1 1.414 0z" stroke-width="0" fill="currentColor" />
  <path d="M4 11a1 1 0 0 1 .117 1.993l-.117 .007h-1a1 1 0 0 1 -.117 -1.993l.117 -.007h1z" stroke-width="0" fill="currentColor" />
  <path d="M21 11a1 1 0 0 1 .117 1.993l-.117 .007h-1a1 1 0 0 1 -.117 -1.993l.117 -.007h1z" stroke-width="0" fill="currentColor" />
  <path d="M6.213 4.81l.094 .083l.7 .7a1 1 0 0 1 -1.32 1.497l-.094 -.083l-.7 -.7a1 1 0 0 1 1.217 -1.567l.102 .07z" stroke-width="0" fill="currentColor" />
  <path d="M19.107 4.893a1 1 0 0 1 .083 1.32l-.083 .094l-.7 .7a1 1 0 0 1 -1.497 -1.32l.083 -.094l.7 -.7a1 1 0 0 1 1.414 0z" stroke-width="0" fill="currentColor" />
  <path d="M12 2a1 1 0 0 1 .993 .883l.007 .117v1a1 1 0 0 1 -1.993 .117l-.007 -.117v-1a1 1 0 0 1 1 -1z" stroke-width="0" fill="currentColor" />
  <path d="M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z" stroke-width="0" fill="#743200" />
</svg>
);

const MoonIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-moon-filled" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M12 1.992a10 10 0 1 0 9.236 13.838c.341 -.82 -.476 -1.644 -1.298 -1.31a6.5 6.5 0 0 1 -6.864 -10.787l.077 -.08c.551 -.63 .113 -1.653 -.758 -1.653h-.266l-.068 -.006l-.06 -.002z" stroke-width="0" fill="#743200" />
</svg>
);

function Home() {
  const [loading, setLoading] = useState(true);
  const [loadingPercentage, setLoadingPercentage] = useState(1);
  const [brisbaneTime, setBrisbaneTime] = useState(moment().tz('Australia/Brisbane').format('HH:mm'));
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingPercentage((prev) => {
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(interval);
          setTimeout(() => setLoading(false), 6000); // Hide loading indicator 2 seconds after reaching 100%
          return prev;
        }
      });
    }, 30); // Adjust this duration to control the speed of the loading percentage

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBrisbaneTime(moment().tz('Australia/Brisbane').format('HH:mm'));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.5,
        duration: 0.5,
        type: 'spring',
        stiffness: 100,
      },
    }),
  };

  const iconVariants = {
    hidden: { opacity: 0, scale: 1.5 },
    visible: (i) => ({
      opacity: 1,
      scale: 1,
      transition: {
        delay: i * 0.3,
        duration: 0.5,
        type: 'spring',
        stiffness: 120,
      },
    }),
  };

  const timeTextVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 5, // Increase the duration to slow down the animation
        type: 'spring',
        stiffness: 50, // Lower the stiffness for a smoother effect
      },
    },
  };

  const handleToggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle('dark-mode', !darkMode);
    document.body.classList.toggle('light-mode', darkMode);
  };

  return (
    <div className="root-div">
      {loading && (
        <div className="loading-indicator">[ LOADING {loadingPercentage}% ]</div>
      )}
      <div className="top-right-text">
      <span>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          CV [CM]
        </motion.p>
        </span>
        <span>
        <button className="toggle-dark-mode" onClick={handleToggleDarkMode}>
          {darkMode ? <SunIcon /> : <MoonIcon />}
        </button>
        </span>
      </div>
      <div className="text-container">
        <motion.h1
          custom={0}
          initial="hidden"
          animate="visible"
          variants={textVariants}
        >
          Work Hub of
        </motion.h1>
        <motion.h2
          custom={1}
          initial="hidden"
          animate="visible"
          variants={textVariants}
        >
          Vipul Gulati
        </motion.h2>
        <motion.p
          className="line-1 anim-typewriter"
          initial="hidden"
          animate="visible"
          variants={textVariants}
        >
          Innovative, meticulous and collaborative.
        </motion.p>
      </div>
      <div className="bottom-container">
        <motion.div
          className="bottom-left-text"
          initial="hidden"
          animate="visible"
          variants={timeTextVariants}
        >
          [ Currently in Australia, {brisbaneTime} ]
        </motion.div>
        <div className="social-icons">
          {[
            { href: 'https://www.instagram.com/thisisvipul.jpg/', icon: faInstagram, className: 'instagram' },
            { href: 'https://gitlab.com/Vipuldeep', icon: faGithub, className: 'github' },
            { href: 'mailto:vipuldeep95@gmail.com', icon: faEnvelope, className: 'mail' },
          ].map((item, index) => (
            <motion.a
              key={item.className}
              href={item.href}
              target="_blank"
              rel="noopener noreferrer"
              className={`icon ${item.className}`}
              custom={index}
              initial="hidden"
              animate="visible"
              variants={iconVariants}
            >
              <FontAwesomeIcon icon={item.icon} size="1x" />
            </motion.a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;